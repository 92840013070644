import React, { Component } from 'react'
import { Redirect } from 'react-router'

const AdminOnlyPageHOC = WrappedComponent =>
  class AdminOnlyPage extends Component {
    constructor(props) {
      super(props)
      this.state = { user: props.user }
    }
    render() {
      return this.state.user.isAdmin ? <WrappedComponent {...this.props} /> : <Redirect to='/' />
    }
  }

export default AdminOnlyPageHOC
