import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap'
import MenuItems from './MenuItems'
import LoginInfo from './LoginInfo'
import logo from '../../images/logo.png'
import firebase from '../firebase'
const auth = firebase.auth()

const menuItems = {
  fileTransfer: {
    title: 'ファイル便',
    admin: false,
    items: {
      '': 'ファイル便',
      FileTransferList: 'ファイル便送信一覧',
    },
  },
  administrators: {
    title: '管理',
    admin: true,
    items: {
      Administrators: '管理者',
      FileTransferListAdmin: 'ファイル便送信一覧',
    },
  },
}
export default class HeaderNav extends Component {
  constructor() {
    super()
    this.state = {
      user: null,
    }
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      this.setState({ user })
    })
  }
  menuContents() {
    const {
      user: { isAdmin },
    } = this.props
    return (
      <div className='navbar-menu d-flex flex-row'>
        {Object.entries(menuItems).map(([k, v]) => {
          if (!v.admin || isAdmin) return <MenuItems key={k} title={v.title} items={v.items} {...this.props} />
        })}
        <div>
          <a
            className='navbar-menu-item mr-4'
            target='_blank'
            href={'https://help-hr.zendesk.com/hc/ja/articles/900000128406'}
          >
            ヘルプ
          </a>
        </div>
      </div>
    )
  }
  render() {
    const { user } = this.state
    return (
      <header className='header-nav'>
        <Navbar color='white' light expand='md'>
          <Link className='navbar-brand' to='/'>
            <img src={logo} alt='Drive-Tool' height='30' />
            <span>Gふぁいる便</span>
          </Link>
          <NavbarToggler />
          <Collapse navbar>
            <Nav className='ml-auto' navbar>
              {user && <LoginInfo user={user} />}
            </Nav>
          </Collapse>
        </Navbar>
        {this.menuContents()}
      </header>
    )
  }
}
