import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

export default class Root extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>{this.props.routes()}</BrowserRouter>
        <ToastContainer />
      </div>
    )
  }
}
