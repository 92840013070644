import React, { Component } from 'react'
import { Input, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion'
import TextareaAutosize from 'react-textarea-autosize'
import DatePicker from 'react-datepicker'
import Toggle from 'react-toggle'
import Select from 'react-select'
import { ReactMultiEmail, isEmail } from 'react-multi-email'
import GooglePicker from 'react-google-picker'
import { confirmAlert } from 'react-confirm-alert'
import { range, isEmpty } from 'lodash'
import { toast } from 'react-toastify'
import { addDays, set } from 'date-fns'
import generatePassword from 'password-generator'
import bytes from 'bytes'
import firebase from '../../firebase'
import UserPage from '../hocs/UserPage'
import OverlayLoading from '../OverlayLoading'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-multi-email/style.css'
import 'react-confirm-alert/src/react-confirm-alert.css'

const db = firebase.firestore()
const sharingRequestsRef = db.collection('sharingRequests')
const usersRef = db.collection('users')
const storageRef = firebase.storage().ref()
const uploadGoogleDrive = firebase.functions().httpsCallable('uploadGoogleDrive')
const copyGoogleDrive = firebase.functions().httpsCallable('copyGoogleDrive')
const clientId = process.env.REACT_APP_CLIENT_ID
const developerKey = process.env.REACT_APP_DEVELOPER_KEY
const adminMail = process.env.REACT_APP_ADMIN_MAIL
const uploadFileSizeLimit = process.env.REACT_APP_UPLOAD_FILE_SIZE_LIMIT

export default UserPage(
  class FileTransfer extends Component {
    constructor() {
      super()
      this.state = {
        isLoading: false,
        file: '',
        downloadNotify: false,
        selfNotify: false,
        downloadCount: { value: 1, label: 1 },
        expireDate: this.setTimeToEndOfDate(addDays(new Date(), 7)),
        emails: [],
        password: '',
        setPassword: false,
        message: 'ファイルをお送りします。よろしくお願いします。',
        memo: '',
      }
    }
    componentDidMount() {
      this.listenSendHistory()
    }
    listenSendHistory() {
      const { user } = this.props
      usersRef.doc(user.uid).onSnapshot(snap => {
        const sendHistory = (snap.data().sendHistory || []).map(_ => ({ id: _, label: _ }))
        this.setState({ sendHistory })
      })
    }
    setTimeToEndOfDate(date) {
      return set(date, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
    }
    handleExpireDate = date => {
      console.log(date)
      this.setState({ ...this.state, expireDate: this.setTimeToEndOfDate(date) })
    }
    handleDownloadNotify = ({ target: { checked } }) => {
      this.setState({ ...this.state, downloadNotify: checked })
    }
    handleSelfNotify = ({ target: { checked } }) => {
      this.setState({ ...this.state, selfNotify: checked })
    }
    handleSetPassword = ({ target: { checked } }) => {
      this.setState({ ...this.state, setPassword: checked })
    }
    onChangeFile = ({
      target: {
        files: [file],
      },
    }) => {
      if (file && uploadFileSizeLimit && file.size > bytes(uploadFileSizeLimit)) {
        alert(`ファイルサイズが制限（${uploadFileSizeLimit}）を超えています（${bytes(file.size)}）。`)
      } else {
        this.setState({ ...this.state, file, gdrive: false })
      }
    }
    onChangeGoogleFile = data => {
      console.log(data)
      if (data.action !== 'picked') return
      const {
        docs: [file],
      } = data
      this.setState({ ...this.state, file, gdrive: true })
    }
    onGooglePickerAuthFailed = data => {
      console.error('Google picker auth failed: ', data)
    }
    onChangeDlCount = value => {
      this.setState({ ...this.state, downloadCount: value })
    }
    onChangePassword = ({ target: { value } }) => {
      console.log(value)
      this.setState({ ...this.state, password: value })
    }
    onChangeMessage = ({ target: { value } }) => {
      this.setState({ ...this.state, message: value })
    }
    onChangeMemo = ({ target: { value } }) => {
      this.setState({ ...this.state, memo: value })
    }
    generatePassword = () => {
      const password = generatePassword(8, false)
      this.setState({ ...this.state, password })
    }
    onChangeSendHistory = value => {
      const { emails } = this.state
      if (emails.includes(value.id)) return
      this.setState({ ...this.state, emails: [...emails, value.id], selectHistory: '' })
    }
    initState = () => {
      this.setState({
        isLoading: false,
        file: '',
        downloadNotify: false,
        selfNotify: false,
        downloadCount: { value: 1, label: 1 },
        expireDate: addDays(new Date(), 7),
        emails: [],
        password: '',
        setPassword: false,
        message: 'ファイルをお送りします。よろしくお願いします。',
        memo: '',
      })
    }
    submit = () => {
      const { file, emails, setPassword, password } = this.state
      if (!file || isEmpty(emails)) {
        alert('必須項目を入力してください')
        return
      }
      if (setPassword && isEmpty(password)) {
        alert('パスワードを設定してください')
        return
      }
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h5>
                ファイル便を実行する前に以下の注意事項を
                <br />
                必ずご確認ください
              </h5>
              <p>-注意事項</p>
              <ul>
                <li>
                  宛先に送付されるメールが第三者に漏洩した場合、送信したファイルも漏洩する場合があります。
                  <br />
                  各自の責任でご使用ください。
                </li>
                <li>
                  万一、誤った宛先へ送信してしまった場合は
                  <br />
                  ファイル便送信履歴画面より取消が可能です。
                </li>
              </ul>
              <div className='text-center'>
                <button onClick={onClose}>キャンセル</button>
                <button
                  onClick={() => {
                    this.upload()
                    onClose()
                  }}
                >
                  了解して使用（OK）
                </button>
              </div>
            </div>
          )
        },
      })
    }
    upload = async () => {
      this.setState({ isLoading: true })
      const { file, gdrive } = this.state
      try {
        const status = await this.createRequest()
        if (gdrive) {
          const loadGapi = () =>
            new Promise((resolve, reject) =>
              window.gapi.load('client', { callback: () => resolve(), onerror: () => reject() })
            )
          await loadGapi()
          await window.gapi.client.load('drive', 'v3')
          const createResponse = await window.gapi.client.drive.permissions.create({
            fileId: file.id,
            resource: {
              role: 'reader',
              type: 'user',
              emailAddress: adminMail,
            },
          })
          console.log({ createResponse })
          const copyResonse = await copyGoogleDrive({ id: status.id })
          console.log({ copyResonse })
        } else {
          const ref = storageRef.child(file.name)
          const result = await ref.put(file)
          console.log(result)
          if (result.state !== 'success') {
            throw Error('storageへのアップロードに失敗しました')
          }
          await uploadGoogleDrive({ id: status.id })
            .then(result => {
              console.log(result)
            })
            .catch(error => {
              throw error
            })
        }
        this.initState()
        toast.success('ファイルをアップロードしました')
      } catch (error) {
        console.error(error)
        toast.error('ファイルのアップロードに失敗しました')
      } finally {
        this.setState({ isLoading: false })
      }
    }
    createRequest = async () => {
      const { user } = this.props
      const {
        file,
        gdrive,
        downloadNotify,
        selfNotify,
        downloadCount,
        expireDate,
        emails,
        password,
        setPassword,
        message,
        memo,
      } = this.state
      return await sharingRequestsRef.add({
        user,
        fileId: file.id || '',
        file: file.name,
        type: file.type,
        gdrive,
        emails,
        count: downloadCount.value,
        downloadNotify,
        expireDate,
        message,
        password,
        setPassword,
        selfNotify,
        memo,
        date: new Date(),
      })
    }
    render() {
      const {
        isLoading,
        sendHistory,
        selectHistory,
        file,
        downloadNotify,
        selfNotify,
        downloadCount,
        expireDate,
        emails,
        password,
        setPassword,
        message,
        memo,
      } = this.state
      const options = range(1, 6).map(i => ({ value: i, label: i }))
      const onChangeGoogleFile = this.onChangeGoogleFile
      return (
        <div className='page'>
          <OverlayLoading loading={isLoading} />
          <div className='container py-3'>
            <div className='d-flex justify-content-center mb-2'>
              <h3>ファイル便</h3>
            </div>
            <div className='d-flex justify-content-center mb-2'>
              <Accordion allowMultipleExpanded='true' preExpanded={['1', '2', '3', '4', '5', '6', '7']}>
                <AccordionItem uuid='1'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className='d-flex'>
                        <div>
                          送信ファイル <span className='badge badge-danger'>必須</span>
                        </div>
                        <div className='small text-black-50 align-self-center pl-3'>
                          ※LocalDrive100MBまで／GoogleDrive無制限
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className='input-group'>
                      <label>
                        <span className='btn btn-primary' style={{ marginRight: '2px' }}>
                          LocalDrive
                          <Input type='file' style={{ display: 'none' }} onChange={this.onChangeFile} />
                        </span>
                      </label>
                      <GooglePicker
                        clientId={clientId}
                        developerKey={developerKey}
                        scope={[
                          'https://www.googleapis.com/auth/drive.readonly',
                          'https://www.googleapis.com/auth/drive',
                          'https://www.googleapis.com/auth/drive.file',
                        ]}
                        onAuthFailed={this.onGooglePickerAuthFailed}
                        viewId={'DOCS'}
                        createPicker={(google, oauthToken) => {
                          const myView = new google.picker.DocsView()
                            .setMode(google.picker.DocsViewMode.LIST)
                            .setSelectFolderEnabled(false)
                            .setOwnedByMe(true)
                          const picker = new window.google.picker.PickerBuilder()
                            .addView(myView)
                            .setOAuthToken(oauthToken)
                            .setDeveloperKey(developerKey)
                            .setCallback(onChangeGoogleFile)
                            .enableFeature(google.picker.Feature.SUPPORT_TEAM_DRIVES)
                            .disableFeature(google.picker.Feature.MINE_ONLY)
                            .setTitle('ファイル選択')

                          picker.build().setVisible(true)
                        }}
                      >
                        <Button color='primary'>GoogleDrive</Button>
                      </GooglePicker>
                      <input
                        type='text'
                        className='form-control'
                        name='fileName'
                        defaultValue={file ? file.name : ''}
                        readOnly='true'
                      />
                      <div className='small text-black-50 pt-3'>
                        「GoogleDrive」では現在以下の制約があります。今後の機能追加をお待ちください。
                        <br />
                        ・マイドライブのみに対応しています。
                        <br />
                        ・Googleスプレッドシート、スライド、ドキュメント等、変換が必要なファイルには対応していません。
                        <br />
                        　ローカルにダウンロードの上、「LocalDrive」よりファイル指定してください。
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='2'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      宛先 <span className='badge badge-danger'>必須</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <ReactMultiEmail
                      placeholder='送信先メールアドレス'
                      emails={emails}
                      onChange={_emails => {
                        this.setState({ ...this.state, emails: _emails })
                      }}
                      validateEmail={email => {
                        return isEmail(email) // return boolean
                      }}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            {email}
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        )
                      }}
                    />
                    <Select
                      className='mt-2'
                      value={selectHistory}
                      placeholder='送信履歴アドレス'
                      onChange={this.onChangeSendHistory}
                      options={sendHistory}
                      isClearable={true}
                    />
                    <div className='small text-black-50 pt-3'>
                      送信先メールアドレスを入力して欄外をクリックすると、次のアドレスが入力できます。
                    </div>
                    <label className='mt-2'>
                      <Toggle checked={selfNotify} onChange={this.handleSelfNotify} />
                      <span className='label-text mx-2'>自分宛に送信完了メールを送る</span>
                    </label>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='3'>
                  <AccordionItemHeading>
                    <AccordionItemButton>送信メッセージ</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <TextareaAutosize className='form-control' value={message} onChange={this.onChangeMessage} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='4'>
                  <AccordionItemHeading>
                    <AccordionItemButton>パスワード</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <label>
                      <Toggle checked={setPassword} onChange={this.handleSetPassword} />
                      <span className='label-text mx-2'>パスワードを設定</span>
                    </label>
                    <div className='mt-2'>
                      <InputGroup>
                        <input
                          type='text'
                          value={password}
                          disabled={setPassword ? '' : 'disabled'}
                          onChange={this.onChangePassword}
                        />
                        <InputGroupAddon addonType='prepend'>
                          <Button color='primary' disabled={!setPassword} onClick={this.generatePassword}>
                            自動生成
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='5'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      ダウンロード有効期限 <span className='badge badge-danger'>必須</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <DatePicker dateFormat='yyyy/MM/dd' selected={expireDate} onChange={this.handleExpireDate} />
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='6'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      ダウンロード回数制限 <span className='badge badge-danger'>必須</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Select
                      styles={{ control: (base, state) => ({ ...base, width: 100 }) }}
                      menuPlacement='auto'
                      menuPosition='fixed'
                      value={options.find(_ => _.value === (downloadCount && downloadCount.value))}
                      onChange={this.onChangeDlCount}
                      options={options}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='7'>
                  <AccordionItemHeading>
                    <AccordionItemButton>ダウンロード通知</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <label>
                      <Toggle checked={downloadNotify} onChange={this.handleDownloadNotify} />
                      <span className='label-text mx-2'>ダウンロードが開始されたら自分宛に通知メールを送信する</span>
                    </label>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid='3'>
                  <AccordionItemHeading>
                    <AccordionItemButton>覚書メモ</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div>
                      <TextareaAutosize className='form-control' value={memo} onChange={this.onChangeMemo} />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className='d-flex justify-content-center mb-2'>
              <Button color='primary' className='float-left' onClick={this.submit}>
                送信
              </Button>
            </div>
          </div>
        </div>
      )
    }
  }
)
