import React, { Component } from 'react'
import { Button, Input, Card, CardHeader, CardBody, CardTitle } from 'reactstrap'
import Toggle from 'react-toggle'
import { format } from 'date-fns'
import firebase from '../../firebase'
import UserPage from '../hocs/UserPage'

const db = firebase.firestore()
const sharingRequestsRef = db.collection('sharingRequests')
const transferFilesRef = db.collection('transferFiles')

export default UserPage(
  class FileTransferDetail extends Component {
    constructor(props) {
      super(props)
      this.state = {}
    }
    componentDidMount() {
      this.listenSharingRequest()
      this.listenTransferFiles()
    }
    listenSharingRequest() {
      const {
        match: {
          params: { id },
        },
      } = this.props
      sharingRequestsRef.doc(id).onSnapshot(_ => {
        const sharingRequest = _ && { id: _.id, ..._.data() }
        this.setState({ sharingRequest })
      })
    }
    listenTransferFiles() {
      const {
        match: {
          params: { id },
        },
      } = this.props
      transferFilesRef.where('transferId', '==', id).onSnapshot(_ => {
        const transferFiles = (_.docs || []).map(_ => {
          return { id: _.id, ..._.data() }
        })
        this.setState({ transferFiles })
      })
    }
    handleDisabled({ target: { checked } }) {
      const {
        match: {
          params: { id },
        },
      } = this.props
      const { transferFiles } = this.state
      sharingRequestsRef.doc(id).set({ disabled: checked }, { merge: true })
      transferFiles.map(({ id }) => {
        transferFilesRef.doc(id).set({ disabled: checked }, { merge: true })
      })
    }
    handleUserDisabled(id, { target: { checked } }) {
      transferFilesRef.doc(id).set({ disabled: checked }, { merge: true })
    }
    onClickBack = () => this.props.history.goBack()
    render() {
      const { sharingRequest, transferFiles } = this.state
      const { id, file, password, message, expireDate, count, downloadNotify, memo, disabled } = sharingRequest || {}
      return (
        <div className='page'>
          <div className='container container-small mt-3'>
            <Card style={{ width: '800px' }}>
              <CardHeader tag='h3'>
                <center>ファイル便送信詳細</center>
              </CardHeader>
              <CardBody>
                <table className='table file mt-3'>
                  <body>
                    <tr>
                      <th>ID</th>
                      <td>{id}</td>
                    </tr>
                    <tr>
                      <th>ファイル名</th>
                      <td>{file}</td>
                    </tr>
                    <tr>
                      <th>パスワード</th>
                      <td>{password || 'なし'}</td>
                    </tr>
                    <tr>
                      <th>メッセージ</th>
                      <td>{message}</td>
                    </tr>
                    <tr>
                      <th>ダウンロード有効期限</th>
                      <td>{expireDate && format(expireDate.toDate(), 'yyyy/MM/dd')}</td>
                    </tr>
                    <tr>
                      <th>ダウンロード回数</th>
                      <td>{count}回</td>
                    </tr>
                    <tr>
                      <th>ダウンロード通知</th>
                      <td>{downloadNotify ? 'あり' : 'なし'}</td>
                    </tr>
                    <tr>
                      <th>管理メモ</th>
                      <td>{memo}</td>
                    </tr>
                    <tr>
                      <th>共有停止</th>
                      <td>
                        <label className='mt-2'>
                          <Toggle checked={disabled} onChange={this.handleDisabled.bind(this)} />
                          <span className='label-text mx-2'>共有を停止する</span>
                        </label>
                      </td>
                    </tr>
                  </body>
                </table>
                <div className='container'>
                  {(transferFiles || []).map(({ id, email, downloadCount, latestAccessDate, disabled }) => {
                    return (
                      <div className='container-small d-flex justify-content-center mt-3'>
                        <Card style={{ width: '600px' }}>
                          <CardHeader tag='h4'>
                            <center>{email}</center>
                          </CardHeader>
                          <CardBody>
                            <table className='table destination'>
                              <body>
                                <tr>
                                  <th>ダウンロード数</th>
                                  <td>{downloadCount}</td>
                                </tr>
                                <tr>
                                  <th>最終ダウンロード日時</th>
                                  <td>
                                    {latestAccessDate ? format(latestAccessDate.toDate(), 'yyyy/MM/dd HH:mm') : 'ー'}
                                  </td>
                                </tr>
                                <tr>
                                  <th>共有停止</th>
                                  <td>
                                    <label className='mt-2'>
                                      <Toggle checked={disabled} onChange={this.handleUserDisabled.bind(this, id)} />
                                      <span className='label-text mx-2'>共有を停止する</span>
                                    </label>
                                  </td>
                                </tr>
                              </body>
                            </table>
                          </CardBody>
                        </Card>
                      </div>
                    )
                  })}
                </div>
              </CardBody>
            </Card>
          </div>
          <div className='container container-small mt-3'>
            <Button color='primary' onClick={this.onClickBack.bind(this)}>
              Back
            </Button>
          </div>
        </div>
      )
    }
  }
)
