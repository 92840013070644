import React, { Component } from 'react'

export default function PublicPageHOC(WrappedComponent) {
  return class PublicPage extends Component {
    constructor() {
      super()
      this.state = {}
    }
    render() {
      return (
        <div>
          <WrappedComponent
            {...{
              ...this.props,
            }}
          />
        </div>
      )
    }
  }
}
