import React, { Component } from 'react'
import { Button } from 'reactstrap'
import firebase from '../firebase'

const auth = firebase.auth()

export default class LoginInfo extends Component {
  constructor() {
    super()
    this.state = {}
  }
  static getDerivedStateFromProps({ user }) {
    return user ? { user } : null
  }
  logout = () => {
    auth.signOut()
    this.setState({ uid: null, email: null, displayName: null })
    window.location.href = '/'
  }
  render() {
    const { user } = this.state
    return (
      <div className='period-selector d-flex flex-row'>
        <div className='m-2'>{user && `${user.displayName}（${user.email}）`}</div>
        <Button color='primary' onClick={this.logout.bind(this)}>
          ログアウト
        </Button>
      </div>
    )
  }
}
