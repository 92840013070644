import React from 'react'
import { render } from 'react-dom'
import Root from './components/Root'
import routes from './routes'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import '../css/application.css'

const renderApp = appRoutes => {
  render(<Root routes={appRoutes} />, document.getElementById('root'))
}

document.addEventListener('DOMContentLoaded', () => {
  renderApp(routes)
})
