import React from 'react'
import classnames from 'classnames'
import { RingLoader } from 'react-spinners'

export default function OverlayLoading({ loading: isLoading }) {
  return (
    <div
      className={classnames('overlay-loading', 'align-items-center', 'justify-content-center', 'flex-column', {
        loading: isLoading,
      })}
    >
      <RingLoader color='white' loading />
      <span>Loading...</span>
    </div>
  )
}
