import React from 'react'
import { Route } from 'react-router'
import Administrators from './components/pages/Administrators'
import FileTransferList from './components/pages/FileTransferList'
import FileTransferListAdmin from './components/pages/FileTransferListAdmin'
import FileTransferDetail from './components/pages/FileTransferDetail'
import FileTransfer from './components/pages/FileTransfer'
import FileDownload from './components/pages/FileDownload'

const routeObjs = [
  {
    exact: true,
    path: '/',
    component: FileTransfer,
  },
  {
    exact: true,
    path: '/fileTransferList',
    component: FileTransferList,
  },
  {
    exact: true,
    path: '/fileTransferListAdmin',
    component: FileTransferListAdmin,
  },
  {
    exact: true,
    path: '/fileTransferDetail/:id',
    component: FileTransferDetail,
  },
  {
    exact: true,
    path: '/fileDownload/:downloadId',
    component: FileDownload,
  },
  {
    exact: true,
    path: '/administrators',
    component: Administrators,
  },
]

export default function routes(extraProps) {
  return (
    <div>
      {routeObjs.map((route, i) => {
        const { exact, path, render, props } = route
        return (
          <Route
            exact={exact}
            path={path}
            render={render || (_ => <route.component {..._} {...props} {...extraProps} />)}
            key={i}
          />
        )
      })}
    </div>
  )
}
