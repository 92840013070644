import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, InputGroup, InputGroupAddon, Card, CardHeader, CardBody, CardTitle } from 'reactstrap'
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap'
import firebase from '../../firebase'
import OverlayLoading from '../OverlayLoading'
import PublicPage from '../hocs/PublicPage'
import logo from '../../../images/logo.png'

const db = firebase.firestore()
const transferFilesRef = db.collection('transferFiles')
const downloadGoogleDrive = firebase.functions().httpsCallable('downloadGoogleDrive')
const passwordVerification = firebase.functions().httpsCallable('passwordVerification')

export default PublicPage(
  class FileTransfer extends Component {
    constructor() {
      super()
      this.state = {
        setPassword: false,
        availableDownload: false,
        isLoading: false,
      }
    }
    componentDidMount() {
      this.listenDownloadInfo()
    }
    listenDownloadInfo() {
      const {
        match: {
          params: { downloadId },
        },
      } = this.props
      transferFilesRef.doc(downloadId).onSnapshot(_ => {
        const downloadInfo = _ && _.data()
        this.setState({ downloadInfo })
      })
    }
    onChangePassword = ({ target: { value } }) => {
      console.log(value)
      this.setState({ password: value })
    }
    confirmPassword = () => {
      const {
        match: {
          params: { downloadId },
        },
      } = this.props
      const { password } = this.state
      passwordVerification({ id: downloadId, password }).then(async ({ data: { result } }) => {
        if (result) {
          this.setState({ availableDownload: true })
        } else {
          alert('パスワードが一致しません')
        }
      })
    }
    download = async () => {
      const {
        match: {
          params: { downloadId },
        },
      } = this.props
      this.setState({ isLoading: true })
      try {
        const result = await downloadGoogleDrive({ id: downloadId })
        if (result.data.status === 'error') {
          alert(result.data.message)
          return
        }
        const {
          data: { fileId, file },
        } = result
        const link = document.createElement('a')
        link.href = `https://drive.google.com/uc?export=download&id=${fileId}`
        link.download = file
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        this.setState({ isLoading: false })
        console.log('download complete')
      } catch (error) {
        console.log(error)
      } finally {
        this.setState({ isLoading: false })
      }
    }
    render() {
      const { downloadInfo, availableDownload, isLoading } = this.state
      const { file, setPassword, disabled } = downloadInfo || {}
      return (
        <div className='page'>
          <OverlayLoading loading={isLoading} />
          <header className='header-nav'>
            <Navbar color='white' light expand='md'>
              <img src={logo} alt='Drive-Tool' height='30' />
              <span>Gふぁいる便</span>
              <NavbarToggler />
              <Collapse navbar>
                <Nav className='ml-auto' navbar></Nav>
              </Collapse>
            </Navbar>
          </header>
          <div className='navbar-menu d-flex flex-row'>
            <div>
              <Link className='navbar-menu-item mr-4'></Link>
            </div>
          </div>
          {
            <div className='administrators'>
              <div className='container container-small py-3'>
                <Card style={{ width: '600px' }}>
                  <CardHeader tag='h3'>
                    <center>ダウンロード(Download)</center>
                  </CardHeader>
                  {disabled ? (
                    <CardBody>
                      <div className='text-center'>ファイル送信者にお問い合わせください</div>
                    </CardBody>
                  ) : (
                    <CardBody>
                      {setPassword && !availableDownload && (
                        <div className='d-flex justify-content-center mt-3'>
                          <InputGroup>
                            <Input
                              type='text'
                              placeholder='パスワード(Password)'
                              onChange={this.onChangePassword.bind(this)}
                            />
                            <InputGroupAddon addonType='prepend'>
                              <Button color='primary' onClick={this.confirmPassword.bind(this)}>
                                OK
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                        </div>
                      )}
                      {(!setPassword || availableDownload) && (
                        <div className='d-flex justify-content-center mt-3'>
                          <div className='container py-3'>
                            <div className='d-flex justify-content-center mt-3'>
                              <CardTitle>下のリンクからファイルをダウンロードしてください。</CardTitle>
                            </div>
                            <div className='d-flex justify-content-center'>
                              <div className='text-black-50 align-self-center'>(Click the link below)</div>
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                              <Link className='navbar-brand' onClick={this.download.bind(this)}>
                                {file}
                              </Link>
                            </div>
                          </div>
                        </div>
                      )}
                    </CardBody>
                  )}
                </Card>
              </div>
            </div>
          }
        </div>
      )
    }
  }
)
