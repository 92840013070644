import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import HeaderNav from '../HeaderNav'

import firebase from '../../firebase'

const auth = firebase.auth()
const db = firebase.firestore()
const usersRef = db.collection('users')
const administratorsRef = db.collection('administrators')

export default function UserPageHOC(WrappedComponent) {
  return class UserPage extends Component {
    constructor() {
      super()
      this.state = {}
    }
    componentWillMount() {
      auth.onAuthStateChanged(async firebaseUser => {
        if (!(firebaseUser && firebaseUser.displayName)) {
          auth.signOut()
          this.setState({ uid: null, email: null, displayName: null })
          this.openLoginModal()
          return
        }
        const { email, uid, displayName } = firebaseUser
        if (!this.isAuthUser(email)) {
          auth.signOut()
          this.setState({ uid: null, email: null, displayName: null })
          this.openLoginModal()
          return
        }
        const isAdmin = await this.isAdminUser(email)
        this.setState({ uid, email, displayName, isAdmin })
      })
    }
    signIn = providerName => {
      const provider = new firebase.auth[`${providerName}AuthProvider`]()
      provider.setCustomParameters({
        prompt: 'select_account',
      })
      auth.signInWithRedirect(provider)
    }
    openLoginModal() {
      this.setState({ shouldShowLoginModal: true })
    }
    componentDidUpdate(_, prevState) {
      if (this.state.uid && !prevState.uid) {
        this.onSetUid()
      }
    }
    onSetUid() {
      this.listenUser()
      this.updateUser()
    }
    listenUser() {
      const { uid } = this.state
      usersRef.doc(uid).onSnapshot(_ => this.setState({ user: _.data() }))
    }
    updateUser() {
      const { uid, email, displayName, isAdmin } = this.state
      usersRef
        .doc(uid)
        .get()
        .then(({ ref, exists }) => {
          ref[exists ? 'update' : 'set']({ uid, email, displayName, isAdmin })
        })
    }
    isAdminUser = async email => {
      return await administratorsRef.get().then(_ => {
        const administrators = (_.docs || []).map(_ => _.data())
        return administrators.some(_ => _.email === email)
      })
    }
    isAuthUser = mail => {
      const authDomains = process.env.REACT_APP_AUTH_MAIL_DOMAINS
      return authDomains.split(',').some(domain => mail.includes(domain))
    }
    render() {
      const { user, shouldShowLoginModal } = this.state
      return (
        <div>
          {user ? (
            <div>
              <HeaderNav {...{ ...this.props, user }} />
              <WrappedComponent
                {...{
                  ...this.props,
                  user,
                }}
              />
            </div>
          ) : (
            <Modal isOpen={shouldShowLoginModal}>
              <ModalHeader>ログイン</ModalHeader>
              <ModalBody className='d-flex justify-content-center p-5'>
                <Button onClick={this.signIn.bind(this, 'Google')}>Googleでログイン</Button>
              </ModalBody>
            </Modal>
          )}
          <ToastContainer />
        </div>
      )
    }
  }
}
