import React, { Component } from 'react'
import { Button, Input } from 'reactstrap'
import { toast } from 'react-toastify'
import firebase from '../../firebase'
import UserPage from '../hocs/UserPage'
import AdminOnlyPage from '../hocs/AdminOnlyPage'

const db = firebase.firestore()
const administratorsRef = db.collection('administrators')

export default UserPage(
  AdminOnlyPage(
    class Administrators extends Component {
      constructor() {
        super()
        this.state = {
          newAddress: '',
        }
      }
      componentDidMount() {
        this.listenAdministrators()
      }
      listenAdministrators() {
        administratorsRef.onSnapshot(_ => {
          const administrators = (_.docs || []).map(_ => {
            return { id: _.id, ..._.data() }
          })
          this.setState({ administrators })
        })
      }
      onChangeNewAddress = ({ target: { value } }) => this.setState({ newAddress: value })
      onClickAdd = async () => {
        const { newAddress } = this.state
        administratorsRef.get().then(_ => {
          const user = ((!_.empty && _.docs) || []).find(_ => _.data().email === newAddress)
          if (!user) {
            administratorsRef.add({ email: newAddress }).then(toast.success('Addition was successful'))
          }
        })
        this.setState({ newAddress: '' })
      }
      onClickRemove = uid =>
        administratorsRef
          .doc(uid)
          .delete()
          .then(toast.success('Deletion was successful'))
      render() {
        const { newAddress, administrators } = this.state
        return (
          <div className='page'>
            <div className='container py-3'>
              <div className='d-flex justify-content-center mb-2'>
                <h3>Administrators Settings</h3>
              </div>
              <div className='d-flex justify-content-start'>
                <div className='col-4'>
                  <Input
                    className='mr-2'
                    placeholder='Add Email Address'
                    value={newAddress}
                    onChange={this.onChangeNewAddress}
                  />
                </div>
                <div className='col-2'>
                  <Button color='primary' onClick={this.onClickAdd} disabled={!newAddress}>
                    <span className='mr-1 fas fa-plus' />
                    Add
                  </Button>
                </div>
              </div>
              <table className='table mt-3'>
                <tbody>
                  {(administrators || []).map(({ id, email }) => {
                    return (
                      <tr key={id}>
                        <td>{email}</td>
                        <td>
                          <Button className='bg-danger text-light' onClick={this.onClickRemove.bind(this, id)}>
                            <span className='fas fa-trash mr-1' />
                            Del
                          </Button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    }
  )
)
