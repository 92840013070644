import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

export default class MenuItems extends Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }
  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }
  onMouseEnter() {
    this.setState({ dropdownOpen: true })
  }

  onMouseLeave() {
    this.setState({ dropdownOpen: false })
  }
  render() {
    const {
      title,
      items,
      match: {
        params: { companyId },
      },
      location: { search },
    } = this.props
    return (
      <Dropdown
        className='navbar-menu-item mr-4'
        onMouseOver={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
      >
        <DropdownToggle tag='span'>{title}</DropdownToggle>
        <DropdownMenu>
          {Object.entries(items).map(([k, v]) => {
            return (
              <Link key={k} className='navbar-menu-select-item' to={`/${k}${search}`}>
                <DropdownItem className='navbar-menu-select-item' tag='div'>
                  {v}
                </DropdownItem>
              </Link>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    )
  }
}
