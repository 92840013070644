import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { orderBy } from 'lodash'
import firebase from '../../firebase'
import UserPage from '../hocs/UserPage'

const db = firebase.firestore()
const sharingRequestsRef = db.collection('sharingRequests')

export default UserPage(
  class FileTransferList extends Component {
    constructor() {
      super()
      this.state = {}
    }
    componentDidMount() {
      this.listenSharingRequests()
    }
    listenSharingRequests() {
      const {
        user: { email },
      } = this.props
      sharingRequestsRef.where('user.email', '==', email).onSnapshot(_ => {
        const sharingRequests = orderBy(
          (_.docs || []).map(_ => {
            return { id: _.id, ..._.data() }
          }),
          _ => _.date,
          'desc'
        )
        this.setState({ sharingRequests })
      })
    }
    render() {
      const { sharingRequests } = this.state
      return (
        <div className='page'>
          <div className='container d-flex flex-column justify-content-center align-items-stretch'>
            <h3 className='text-center mt-3'>
              <u>ファイル便送信一覧</u>
            </h3>
            <div className='d-flex justify-content-center mt-3'>
              <table className='table table-border table-striped'>
                <thead className='thead-light'>
                  <tr>
                    <th width='15%'>送信日時</th>
                    <th width='30%'>ファイル名</th>
                    <th width='10%'>実行結果</th>
                    <th width='23%'>覚書メモ</th>
                    <th width='22%'>詳細</th>
                  </tr>
                </thead>
                <tbody>
                  {(sharingRequests || []).map(({ id, date, file, status, memo }) => {
                    return (
                      <tr key={id}>
                        <td>{date && format(date.toDate(), 'yyyy/MM/dd HH:mm')}</td>
                        <td>{file}</td>
                        <td>{status}</td>
                        <td>{memo}</td>
                        <td>
                          <Link to={`/fileTransferDetail/${id}`}>{id}</Link>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
    }
  }
)
